(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/poy-table/assets/javascripts/poy-table.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/poy-table/assets/javascripts/poy-table.js');
"use strict";


const {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell
} = svs.ui.reactTable;
const {
  Currency: formatCurrency
} = svs.utils.format;
const PoyTable = _ref => {
  let {
    numberOfPlayers,
    playerList,
    isCurrency,
    isNameSearch = false,
    searchString
  } = _ref;
  const playerValue = value => {
    if (value && typeof value === 'number') {
      if (isCurrency) {
        return "".concat(formatCurrency(value), " kr");
      }
      return value;
    }
    return '-';
  };
  if (!playerList.length) {
    const noDataText = isNameSearch ? "Hittar inget som matchar din s\xF6kning \"".concat(searchString, "\"") : 'Hittar ingen rankinglista';
    return React.createElement("p", {
      className: "h3 f-content f-content-inverted"
    }, noDataText);
  }
  return React.createElement(Table, {
    className: "poy-table poy-table-standard f-content",
    large: true
  }, React.createElement(TableHead, null, React.createElement(TableRow, null, React.createElement(TableHeader, null, "Position"), React.createElement(TableHeader, null, "Deltagare", numberOfPlayers && typeof numberOfPlayers === 'number' && " (".concat(numberOfPlayers, " st)")), React.createElement(TableHeader, null, isCurrency ? 'Vinst' : 'Poäng'))), React.createElement(TableBody, null, playerList.map(player => React.createElement(TableRow, {
    key: player.name
  }, React.createElement(TableCell, null, player.rankingPos), React.createElement(TableCell, null, player.name), React.createElement(TableCell, null, playerValue(player.value))))));
};
setGlobal('svs.poker_react.components.PoyTable', PoyTable);

 })(window);