(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/modules/poker-react/components/poy-table/assets/javascripts/poy-table-scrollable.js') >= 0) return;  svs.modules.push('/modules/poker-react/components/poy-table/assets/javascripts/poy-table-scrollable.js');
"use strict";


const {
  useRef,
  useState,
  useContext,
  createContext
} = React;
const {
  Table,
  TableHead,
  TableRow,
  TableHeader,
  TableBody,
  TableCell
} = svs.ui.reactTable;
const {
  FixedSizeList
} = ReactWindow;
const ROW_HEIGHT = 48;
const PoyTableContext = createContext({
  top: 0
});
const Inner = _ref => {
  let {
    children
  } = _ref;
  const {
    top
  } = useContext(PoyTableContext);
  return React.createElement(Table, {
    className: "poy-table poy-table-scrollable f-content",
    large: true,
    style: {
      top
    }
  }, React.createElement(TableBody, null, children));
};
const Row = _ref2 => {
  let {
    index,
    data
  } = _ref2;
  const player = data[index];
  return React.createElement(TableRow, {
    className: player.rankingPos % 2 !== 0 ? 'odd' : '',
    key: player.rankingPos
  }, React.createElement(TableCell, null, player.rankingPos), React.createElement(TableCell, null, player.name), React.createElement(TableCell, null, player.value));
};
const PoyTableScrollable = _ref3 => {
  let {
    numberOfPlayers,
    playerList,
    isNameSearch = false,
    searchString
  } = _ref3;
  const listRef = useRef();
  const [top, setTop] = useState(0);
  if (!playerList.length) {
    const noDataText = isNameSearch ? "Hittar inget som matchar din s\xF6kning \"".concat(searchString, "\"") : 'Hittar ingen rankinglista';
    return React.createElement("p", {
      className: "h3 f-content f-content-inverted"
    }, noDataText);
  }
  return React.createElement(React.Fragment, null, React.createElement("div", {
    className: "scrollable-table-header-container"
  }, React.createElement(Table, {
    className: "poy-table f-content",
    large: true
  }, React.createElement(TableHead, null, React.createElement(TableRow, null, React.createElement(TableHeader, null, "Position"), React.createElement(TableHeader, null, "Deltagare", numberOfPlayers && " (".concat(numberOfPlayers, " st)")), React.createElement(TableHeader, null, "Po\xE4ng"))))), React.createElement(PoyTableContext.Provider, {
    value: {
      top
    }
  }, React.createElement(FixedSizeList, {
    height: ROW_HEIGHT * 10,
    innerElementType: Inner,
    itemCount: playerList.length,
    itemData: playerList,
    itemSize: ROW_HEIGHT,
    onItemsRendered: _ref4 => {
      var _listRef$current;
      let {
        overscanStartIndex
      } = _ref4;
      const style = (_listRef$current = listRef.current) === null || _listRef$current === void 0 ? void 0 : _listRef$current._getItemStyle(overscanStartIndex);
      const offset = style && style.top || 0;
      setTop(offset);
    },
    ref: el => listRef.current = el,
    width: "100%"
  }, Row)));
};
setGlobal('svs.poker_react.components.PoyTableScrollable', PoyTableScrollable);

 })(window);